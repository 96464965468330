<template>
    <div class="wraper pagezclist">
        <el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false" :visible.sync="drawer"
                   :with-header="false">
            <div class="drawer_box">
                <el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
                    <el-button size="small" type="primary" plain icon="el-icon-search" @click="onSearch">查询
                    </el-button>
                    <el-button size="small" type="primary" plain @click="onReset">重置
                    </el-button>
                </el-row>
                <el-divider style="width: 100%"></el-divider>
                <el-form :model="queryForm" ref="queryForm" label-width="10px">
                    <el-row>分字段搜索</el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-input v-model="queryForm.title" size="mini" placeholder="耗材名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-select v-model="queryForm.zc_gztype" size="mini" :disabled="disabled" placeholder="购置方式">
                                    <el-option label="外购" value="外购"></el-option>
                                    <el-option label="租赁" value="租赁"></el-option>
                                    <el-option label="借用" value="借用"></el-option>
                                    <el-option label="自行建造" value="自行建造"></el-option>
                                    <el-option label="其他方式" value="其他方式"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-input v-model="queryForm.pinpai" size="mini" placeholder="品牌"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-input v-model="queryForm.xinghao" size="mini" placeholder="型号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-input v-model="queryForm.jldw" size="mini" placeholder="计量单位"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" class="labelCss">
                            <el-form-item label="价值区间:" label-width="80px">
                                <div class="flexStart">
                                    <el-input v-model="queryForm.jine1" size="mini" placeholder="最小值"></el-input>
                                    <span style="margin: 0 10px;">-</span>
                                    <el-input v-model="queryForm.jine2" size="mini" placeholder="最大值"></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>筛选</el-row>
                    <el-row>
                        <!-- <el-col :span="12">
                            <el-form-item label="">
                                <el-cascader ref="myCascader1" v-model="queryForm.zc_typeid" :options="typeList" size="mini" :props="optionProps1"
                                             :show-all-levels="true" filterable placeholder="耗材分类" @change="handleCascaderChange1"></el-cascader>
                            </el-form-item>
                        </el-col> -->
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-cascader ref="myCascader" v-model="queryForm.zc_storeid" :options="areaList" size="mini" :props="optionProps"
                                             :show-all-levels="true" filterable placeholder="所在位置" @change="handleCascaderChange"></el-cascader>
                            </el-form-item>
                        </el-col>
                        
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-date-picker v-model="queryForm.zc_date" type="date" size="mini" value-format="yyyy-MM-dd" placeholder="购置/借用日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-drawer>
        <div id="printDiv" v-if="showPrinter">ahahahahahha</div>
        <el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
            <div class="flex_1">
                <el-button size="small" type="primary" plain icon="el-icon-plus" @click="add">新增
                </el-button>
                <el-dropdown split-button trigger="click" type="primary" plain size="small" @command="handleCommand" class="cus_dropdown"
                             style="margin-left: 10px;">
                    操作
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="false" :command="0">导入耗材单</el-dropdown-item>
                        <el-dropdown-item :command="1">耗材派发</el-dropdown-item>
                        <el-dropdown-item :command="2">耗材申领</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown v-if="false" split-button trigger="click" type="primary" plain size="small" class="cus_dropdown" style="margin-left: 10px;"><i
                    class="el-icon-printer" style="margin-right: 5px;"></i>打印
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item divided>
                            <div @click="print_ruku">打印入库单</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button style="margin-left: 10px;" size="small" type="danger" plain icon="el-icon-delete" @click="delSlct">删除
                </el-button>
                <el-link v-if="false" type="primary" :href="'./static/zichan_temp.xls'" style="margin-left: 20px;" target="_blank">下载模板</el-link>
            
            </div>
            <div>
                <el-input v-model="queryForm.title" size="small" clearable style="width: 200px!important;" placeholder="耗材名称">
                    <el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>
                </el-input>
                <el-button type="text" @click="drawer=true" style="color: #999999;margin-left: 10px;">
                    <i class="el-icon-s-operation"></i>&nbsp;高级搜索
                </el-button>
            </div>
        </el-row>
        <el-table :data="DataList" height="calc( 100% - 100px)" @selection-change="handleSelectionChange" style="width: 100%;"
                  stripe border size="small">
            <el-table-column type="index" label="编号" width="50"></el-table-column>
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="title" label="耗材名称(点击查看详情)" width="200">
                <template slot-scope="scope">
                    <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{scope.row.title}}</div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="typename1" label="耗材分类" width="120"/> -->
            <el-table-column prop="storename" label="所在位置" min-width="120">
                <template slot-scope="scope">
                    {{scope.row.storename3}} {{scope.row.storename2}} {{scope.row.storename1}}
                </template>
            </el-table-column>
            <el-table-column prop="pinpai" label="品牌"></el-table-column>
            <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="jine" label="价格" width="100" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span>￥{{scope.row.jine}}</span>
				</template>
			</el-table-column>
            <el-table-column prop="used_num" label="已用数量" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="store_num" label="剩余数量" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="yujing_num" label="预警数量" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="last_yujing" label="预警时间" min-width="100" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.last_yujing?scope.row.last_yujing.substr(0,10):'-'}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-tag size="mini" style="margin-left: 10px;cursor: pointer" @click="editItem(scope.row)"><i
                        class="el-icon-edit"></i></el-tag>
                    <el-tag type="danger" size="mini" style="margin-left: 10px;cursor: pointer;"
                            @click="removeItem(scope.row)"><i class="el-icon-delete"></i></el-tag>
                    <el-dropdown style="margin-left: 10px;" trigger="click" size="small">
                        <div class="el-dropdown-link">
                            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <span @click="showStore(scope.row)">增加库存</span>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="false">
                                <span @click="handleShenlingSP(scope.row)">申领审批</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="cus_page"
                       @size-change="handleSizeChange"
                       @current-change="paginate"
                       :current-page.sync="page.current_page"
                       :page-sizes="[10, 50, 100]"
                       :page-size="page.pre_page"
                       layout="total,sizes, prev, pager, next"
                       :total="page.count">
        </el-pagination>
        <!--新增耗材-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="1000px" custom-class="width_800 cus_dialog"
                   :close-on-click-modal="false">
            <el-form :model="EditItem" ref="addzc" label-width="120px">
                <div class="titleBar flexStart">
                    <div class="div flexStart">耗材信息</div>
                </div>
                <el-row style="margin-top: 10px;">
                    <el-col :span="12">
                        <el-form-item label="耗材名称:" prop="title">
                            <el-input v-model="EditItem.title" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <!-- <el-col :span="10">
                        <el-form-item label="耗材分类:" prop="zc_typeid">
                            <el-cascader ref="myCascader1" v-model="EditItem.zc_typeid" :options="typeList" size="mini" :disabled="disabled"
                                         :props="optionProps1" :show-all-levels="true" filterable placeholder="请选择" @change="handleCascaderChange1"></el-cascader>
                        </el-form-item>
                    </el-col>
					<el-col :span="2">
						<div style="text-align: center;  padding-top: 8px;">
							 <el-link href="#/hctype" type="primary">新增分类</el-link>
						</div>
					  
					</el-col> -->
                    <!-- <el-col :span="12">
                        <el-form-item label="计量单位:" prop="jldw">
                            <el-input v-model="EditItem.jldw" size="mini" disabled placeholder="请选择分类"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="12">
                        <el-form-item label="所在位置:" prop="zc_storeid">
                            <el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini" :disabled="disabled"
                                         :props="optionProps" :show-all-levels="true" filterable placeholder="请选择" @change="handleCascaderChange"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="购置方式:" prop="zc_gztype">
                            <el-select v-model="EditItem.zc_gztype" size="mini" :disabled="disabled" placeholder="请选择">
                                <el-option label="外购" value="外购"></el-option>
                                <el-option label="租赁" value="租赁"></el-option>
                                <el-option label="借用" value="借用"></el-option>
                                <el-option label="自行建造" value="自行建造"></el-option>
                                <el-option label="其他方式" value="其他方式"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="购置/借用日期:" prop="zc_date">
                            <el-date-picker v-model="EditItem.zc_date" type="date" size="mini" :disabled="disabled" value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="品牌:" prop="pinpai">
                            <el-input v-model="EditItem.pinpai" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="型号:" prop="xinghao">
                            <el-input v-model="EditItem.xinghao" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
					
                    <el-col :span="12" v-for="(zd,idx) in EditItem.zdjson" :key="idx">
                        <el-form-item :label="zd.name+':'">
                            <el-input v-if="!zd.type||zd.type=='string'" size="small" v-model="zd.value" :disabled="disabled" placeholder="请输入"></el-input>
                            <el-input-number v-if="zd.type=='number'" v-model="zd.value" size="small" :disabled="disabled" controls-position="right"
                                             placeholder="请输入"></el-input-number>
                            <el-date-picker v-if="zd.type=='date'" v-model="zd.value" style="width: 100%" type="date" size="small" :disabled="disabled"
                                            value-format="yyyy-MM-dd" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
    
                    <el-col :span="12">
                        <el-form-item label="价值金额:" prop="jine">
                            <el-input v-model="EditItem.jine" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="数量:">
                            <el-input-number v-model="EditItem.store_num" size="mini" :disabled="disabled" controls-position="right" placeholder="请输入"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="描述说明:" prop="zcdesc">
                            <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.zcdesc" size="mini" :disabled="disabled"
                                      placeholder="描述说明"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="上传图片:" v-show="!disabled">
                            <el-upload action="" :http-request="uploadOss" :on-success="uploadCover" :on-preview="uploadPreview"
                                       :on-remove="handleRemove" :file-list="files" name="image">
                                <el-button size="mini" plain icon="el-icon-upload2" type="primary">点击上传
                                </el-button>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="上传图片:" v-show="disabled&&files&&files.length>0">
                            <!-- <a href="javascript:;" @click="uploadPreview(f)">{{f.filename}}</a> -->
                            <div class="updimage" v-for="(f,idx) in files" :style="{backgroundImage:'url('+f.src+')'}">
                                <img :src="f.src" onclick="viewImage(this)" style="opacity: 0;"/>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="预警数量:">
                            <el-input-number v-model="EditItem.yujing_num" size="mini" :disabled="disabled" controls-position="right" placeholder="请输入"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
    
                <el-divider content-position="left">
                    <el-button type="text" size="small" @click="addCusField">字段不够?点此添加</el-button>
                </el-divider>
                <div class="titleBar flexStart">
                    <div class="div flexStart">维保信息</div>
                </div>
                <el-row style="margin-top: 10px;">
                    <el-col :span="12">
                        <el-form-item label="维保供应商:" prop="wb_gxy">
                            <el-input v-model="EditItem.wb_gxy" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="维保联系人:" prop="wb_lxr">
                            <el-input v-model="EditItem.wb_lxr" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="维保电话:" prop="wb_phone">
                            <el-input v-model="EditItem.wb_phone" size="mini" :disabled="disabled" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="维保到期时间:" prop="wb_deadline">
                            <el-date-picker v-model="EditItem.wb_deadline" type="date" size="mini" :disabled="disabled" value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="维保说明:" prop="wb_desc">
                            <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.wb_desc" size="mini" :disabled="disabled"
                                      placeholder="维保说明"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div v-if="disabled">
                    <div class="titleBar flexStart">
                        <div class="div flexStart">领用人列表</div>
                    </div>
                    <el-row style="margin-top: 20px;">
                        <el-table :data="logList" size="small" height="50vh" border style="width: 100%">
                            <el-table-column prop="cdate" label="操作时间">
                            </el-table-column>
                            <el-table-column prop="create_uid" label="操作人">
                            </el-table-column>
                            <el-table-column prop="hc_name" label="耗材名称">
                            </el-table-column>
                            <el-table-column prop="target_name" label="领用人">
                            </el-table-column>
                            <el-table-column prop="hc_num" label="领用数量">
                            </el-table-column>
                            <el-table-column v-if="false" prop="zc_where" label="领用后位置">
                            </el-table-column>
                        
                        </el-table>
                    </el-row>
                </div>
            </el-form>
            <div slot="footer">
                <el-button v-if="!disabled" type="primary" @click="saveForm()">保存</el-button>
                
                <el-button v-if="disabled" type="primary" @click="dialogVisible=false">关闭</el-button>
            </div>
        </el-dialog>
        <!--        派发单-->
        <el-dialog title="派发单" :visible.sync="dialogVisible_distribute" width="1000px" custom-class="width_800 cus_dialog"
                   :close-on-click-modal="false">
            <el-form :model="EditItem" ref="EditItem" label-width="100px">
                <el-row class="flexStart">
                    <el-form-item label="领用人:">
                        <el-button size="mini" type="primary" plain
                                   @click="showUser(1)">选择</el-button>
                        <el-tag v-if="EditItem.target_users&&EditItem.target_users.length>0"
                                v-for="(uitem,uidx) in EditItem.target_users" :key="uidx"
                                style="margin-left: 10px;" size="mini"
                                type="success" closable @close="del_target_users(uidx)">{{uitem.name}}</el-tag>
                    </el-form-item>
                </el-row>
               <el-row>
                   <el-form-item label="领用日期:">
                       <el-date-picker v-model="EditItem.sq_date" type="date" size="mini" style="width: 160px!important;"
                                       value-format="yyyy-MM-dd" placeholder="选择日期">
                       </el-date-picker>
                   </el-form-item>
               </el-row>
                <el-row>
                    <el-form-item label="附件:" v-show="!disabled">
                        <el-upload action="" :http-request="uploadOss" :on-success="uploadCover" :on-preview="uploadPreview"
                                   :on-remove="handleRemove" :file-list="files" name="image">
                            <el-button size="mini" plain icon="el-icon-upload2" type="primary">点击上传
                            </el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="附件:" v-show="disabled&&files&&files.length>0">
                        <div class="updimage" v-for="(f,idx) in files" :style="{backgroundImage:'url('+f.src+')'}">
                            <img :src="f.src" onclick="viewImage(this)" style="opacity: 0;"/>
                        </div>
                    </el-form-item>
                </el-row>
                <el-divider style="width: 100%"></el-divider>
                <el-row class="flexStart">
                    <el-form-item label="派发数量:" style="margin-bottom: 0!important;">
                        <el-input-number v-model="EditItem.cus_paifanum" size="mini"
                                         controls-position="right" placeholder="输入派发数量"
                                         style="width: 140px;"></el-input-number>
                    </el-form-item>
                    <el-tooltip class="item" effect="dark" content='将下表中"派发数量"一列全部设置为该值' placement="top-start">
                        <el-button :disabled="EditItem.cus_paifanum<=0||!EditItem.cus_paifanum" size="mini" type="primary" plain
                                   @click="applyAllNum">全部应用
                        </el-button>
                    </el-tooltip>
                    
                    <el-divider direction="vertical"></el-divider>
    
                    <el-button size="mini" type="primary" plain icon="el-icon-plus"
                               @click="dialogVisible_zcList=true">选择耗材
                    </el-button>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-table :data="EditItem.hclist" border style="width: 100%" size="small">
                        <el-table-column label="序号" type="index" width="55"></el-table-column>
                        <el-table-column prop="title" label="耗材名称" min-width="120"></el-table-column>
                        <!-- <el-table-column prop="typename" label="耗材分类" width="120" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                {{scope.row.typename1}} {{scope.row.typename1}}
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="storename" label="所在位置" min-width="120" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                {{scope.row.storename1}} {{scope.row.storename2}} {{scope.row.storename3}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="pinpai" label="品牌"></el-table-column>
                        <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="store_num" label="派发数量" width="140">
                           <template slot-scope="scope">
                               <el-input-number v-model="scope.row.num" size="mini" style="width: 100%"
                                                controls-position="right" placeholder="请输入"></el-input-number>
                           </template>
                        </el-table-column>
                        <el-table-column label="操作" max-width="80" fixed="right">
                            <template slot-scope="scope">
                                <el-tag size="mini" style="cursor: pointer;"
                                        @click="removeHC(scope.row)">移除</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="saveDistribute">保存</el-button>
            </div>
        </el-dialog>
        <!--        耗材申领-->
        <el-dialog title="耗材申领" :visible.sync="dialogVisible_shenling" width="1000px" custom-class="width_800 cus_dialog"
                   :close-on-click-modal="false">
            <el-form :model="EditItem" ref="EditItem" label-width="100px">
                <el-row class="flexStart">
                    <el-form-item label="申领人:">
                        <div class="flexStart">
                            <el-input v-model="EditItem.name" size="mini" disabled type="primary" plain placeholder="请选择">
                            </el-input>
                            <el-button size="mini" type="primary" plain @click="showUser(2)">选择</el-button>
                        </div>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="附件:" v-show="!disabled">
                        <el-upload action="" :http-request="uploadOss" :on-success="uploadCover" :on-preview="uploadPreview"
                                   :on-remove="handleRemove" :file-list="files" name="image">
                            <el-button size="mini" plain icon="el-icon-upload2" type="primary">点击上传
                            </el-button>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="附件:" v-show="disabled&&files&&files.length>0">
                        <div class="updimage" v-for="(f,idx) in files" :style="{backgroundImage:'url('+f.src+')'}">
                            <img :src="f.src" onclick="viewImage(this)" style="opacity: 0;"/>
                        </div>
                    </el-form-item>
                    <el-form-item label="申领说明:" prop="zcdesc">
                        <el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.remark" size="mini"
                                  :disabled="disabled"
                                  placeholder="申领说明"></el-input>
                    </el-form-item>
                </el-row>
               
                <el-divider style="width: 100%"></el-divider>
                <el-row class="flexStart">
                    <el-form-item label="申领数量:" style="margin-bottom: 0!important;">
                        <el-input-number v-model="EditItem.cus_paifanum" size="mini"
                                         controls-position="right" placeholder="输入申领数量"
                                         style="width: 140px;"></el-input-number>
                    </el-form-item>
                    <el-tooltip class="item" effect="dark" content='将下表中"申领数量"一列全部设置为该值' placement="top-start">
                        <el-button :disabled="EditItem.cus_paifanum<=0||!EditItem.cus_paifanum" size="mini" type="primary" plain
                                   @click="applyAllNum">全部应用
                        </el-button>
                    </el-tooltip>
                
                    <el-divider direction="vertical"></el-divider>
                
                    <el-button size="mini" type="primary" plain icon="el-icon-plus"
                               @click="dialogVisible_zcList=true">选择耗材
                    </el-button>
                </el-row>
                <el-row style="margin-top: 20px;">
                    <el-table :data="EditItem.hclist" border style="width: 100%" size="small">
                        <el-table-column label="序号" type="index" width="55"></el-table-column>
                        <el-table-column prop="title" label="耗材名称" min-width="120"></el-table-column>
                        <!-- <el-table-column prop="typename" label="耗材分类" width="120" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                {{scope.row.typename1}} {{scope.row.typename1}}
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="storename" label="所在位置" min-width="120" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                {{scope.row.storename1}} {{scope.row.storename2}} {{scope.row.storename3}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="pinpai" label="品牌"></el-table-column>
                        <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="store_num" label="申领数量" width="140">
                            <template slot-scope="scope">
                                <el-input-number v-model="scope.row.num" size="mini" style="width: 100%"
                                                 controls-position="right" placeholder="请输入"></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" max-width="80" fixed="right">
                            <template slot-scope="scope">
                                <el-tag size="mini" style="cursor: pointer;"
                                        @click="removeHC(scope.row)">移除</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="saveShenling">保存</el-button>
            </div>
        </el-dialog>
    
        <!--        选择资产-->
        <el-dialog title="选择资产" :append-to-body="true" :visible.sync="dialogVisible_zcList" width="800px"
                   custom-class="width_800 cus_dialog">
            <div>
                <el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false"
                           :visible.sync="drawer"
                           :with-header="false">
                    <div class="drawer_box">
                        <el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
                            <el-button size="small" type="primary" plain icon="el-icon-search" @click="onSearch">查询
                            </el-button>
                            <el-button size="small" type="primary" plain @click="onReset">重置
                            </el-button>
                        </el-row>
                        <el-divider style="width: 100%"></el-divider>
                        <el-form :model="queryForm" ref="queryForm" label-width="10px">
                            <el-row>分字段搜索</el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="">
                                        <el-input v-model="queryForm.title" size="mini" placeholder="耗材名称"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="">
                                        <el-select v-model="queryForm.zc_gztype" size="mini" :disabled="disabled" placeholder="购置方式">
                                            <el-option label="外购" value="外购"></el-option>
                                            <el-option label="租赁" value="租赁"></el-option>
                                            <el-option label="借用" value="借用"></el-option>
                                            <el-option label="自行建造" value="自行建造"></el-option>
                                            <el-option label="其他方式" value="其他方式"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="">
                                        <el-input v-model="queryForm.pinpai" size="mini" placeholder="品牌"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="">
                                        <el-input v-model="queryForm.xinghao" size="mini" placeholder="型号"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="">
                                        <el-input v-model="queryForm.jldw" size="mini" placeholder="计量单位"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" class="labelCss">
                                    <el-form-item label="价值区间:" label-width="80px">
                                        <div class="flexStart">
                                            <el-input v-model="queryForm.jine1" size="mini" placeholder="最小值"></el-input>
                                            <span style="margin: 0 10px;">-</span>
                                            <el-input v-model="queryForm.jine2" size="mini" placeholder="最大值"></el-input>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>筛选</el-row>
                            <el-row>
                                <!-- <el-col :span="12">
                                    <el-form-item label="">
                                        <el-cascader ref="myCascader1" v-model="queryForm.zc_typeid" :options="typeList" size="mini" :props="optionProps1"
                                                     :show-all-levels="true" filterable placeholder="耗材分类" @change="handleCascaderChange1"></el-cascader>
                                    </el-form-item>
                                </el-col> -->
                                <el-col :span="12">
                                    <el-form-item label="">
                                        <el-cascader ref="myCascader" v-model="queryForm.zc_storeid" :options="areaList" size="mini" :props="optionProps"
                                                     :show-all-levels="true" filterable placeholder="所在位置" @change="handleCascaderChange"></el-cascader>
                                    </el-form-item>
                                </el-col>
                
                                <el-col :span="12">
                                    <el-form-item label="">
                                        <el-date-picker v-model="queryForm.zc_date" type="date" size="mini" value-format="yyyy-MM-dd" placeholder="购置/借用日期">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-drawer>
                <div>
                    <el-input v-model="queryForm.title" size="small" clearable style="width: 200px!important;" placeholder="耗材名称">
                        <el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>
                    </el-input>
                    <el-button type="text" @click="drawer=true" style="color: #999999;margin-left: 10px;">
                        <i class="el-icon-s-operation"></i>&nbsp;高级搜索
                    </el-button>
                </div>
                <el-table :data="DataList" height="calc( 100% - 100px)" @selection-change="handleSelectionChange" style="width: 100%;"
                          stripe border size="small">
                    <el-table-column label="操作" width="60" fixed="left">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.store_num>0" size="mini" style="cursor: pointer;"
                                    @click="chooseHC(scope.row)">选择</el-tag>
                            <el-tag v-else size="mini" type="info" plain
                                    style="cursor: not-allowed;">
                                选择</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column type="index" label="编号" width="50"></el-table-column>
                    <el-table-column prop="title" label="耗材名称" min-width="120">
                        <template slot-scope="scope">
                            <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{scope.row.title}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="typename" label="耗材分类" width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.typename1}} {{scope.row.typename1}}
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="storename" label="所在位置" min-width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.storename1}} {{scope.row.storename2}} {{scope.row.storename3}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="pinpai" label="品牌"></el-table-column>
                    <el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="used_num" label="已用数量" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="store_num" label="剩余数量" :show-overflow-tooltip="true"></el-table-column>
                </el-table>
                <el-pagination class="cus_page"
                               @size-change="handleSizeChange_hc"
                               @current-change="paginate_hc"
                               :current-page.sync="page_hc.current_page"
                               :page-sizes="[10, 50, 100]"
                               :page-size="page_hc.pre_page"
                               layout="total,sizes, prev, pager, next"
                               :total="page_hc.count">
                </el-pagination>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="dialogVisible_zcList=false">关闭</el-button>
            </div>
        </el-dialog>
    
        <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>
        
        <biaoqian v-if="showBiaoqian" :zclist="bqZcList"></biaoqian>
        <div style="display: none;">
            <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch" :show-file-list="false"
                       name="image">
                
                <el-button size="mini" id="addXlsbtn">+</el-button>
            </el-upload>
        </div>
        
        
        <el-dialog title="错误日志" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
            <div style="padding-top: 10px;" v-for="item in ErrLog">{{item}}</div>
        </el-dialog>
        <!--       新增字段-->
        <el-dialog title="自定义字段" :visible.sync="showCusField" width="600px" custom-class="width_800 cus_dialog">
        
        
            <el-form :model="FieldItem" ref="FieldItem" label-width="120px">
                <el-row style="margin-top: 20px;">
                    <el-form-item label="所属类型:">
                        <el-input v-model="FieldItem.value" disabled></el-input>
                    </el-form-item>
                
                    <el-form-item label="设备名称:">
                        <el-input v-model="FieldItem.cname" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="英文编码:">
                        <el-input v-model="FieldItem.ename" disabled></el-input>
                    </el-form-item>
                    <el-divider style="width: 100%"></el-divider>
                    <div style="color: #409EFF;margin: 10px 0 10px 0;">自定义字段</div>
                    <el-row v-for="(c,idx) in FieldItem.zdjson" class="flexStart drawer_box">
                        <el-col :span="11">
                            <el-form-item label="字段名称:" label-width="80px">
                                <el-input size="small" v-model="c.name" placeholder="字段名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="9" style="margin-left: 10px;">
                            <el-form-item label="字段类型" label-width="80px">
                                <el-select size="small" v-model="c.type" placeholder="请选择">
                                    <el-option value="string" label="文本"></el-option>
                                    <el-option value="number" label="数值"></el-option>
                                    <el-option value="date" label="日期"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-tag @click="delCus(c,idx)" type="danger" size="mini" style="margin-left: 20px;"><i class="el-icon-remove"></i></el-tag>
                    </el-row>
                    <el-row style="margin: 20px 0">
                        <el-button @click="addCus" title="新增系定义属性" size="mini" type="primary" plain icon="el-icon-plus" circle></el-button>
                    </el-row>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="saveCusField">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog title="增加库存" :visible.sync="dialogVisible_store" width="600px" custom-class="width_800 cus_dialog">
        
        
            <el-form :model="EditItem" :rules="rules_store" ref="addstore" label-width="90px"
                     @submit.native.prevent>
                <el-row style="margin-top: 20px;">
                    <el-form-item label="入库数量:" prop="num">
                        <el-input v-model.number="EditItem.num"></el-input>
                    </el-form-item>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="saveStore('addstore')">保存</el-button>
            </div>
        </el-dialog>
		
    </div>
</template>

<script>
	import Pagination from '../../com/pagination.vue'
	import Deptuser from '../../com/deptuser.vue'
	import biaoqian from '../zc/biaoqian.vue'
	

	export default {
		components: {
			Pagination,
			Deptuser,
			biaoqian
		},
		data () {
			var checkNum = (rule, value, callback) => {
				if ( !value&&value!=0) {
					return callback(new Error('请填写入库数量!'));
				}
				if ( !Number.isInteger(value)) {
					callback(new Error('请输入数字值'));
				} else {
					if (value <= 0) {
						callback(new Error('不能小于0'));
					} else {
						callback();
					}
				}
			};

			return {
				DataList: [],
				multipleSelection: [],
				queryForm: {},
				page: {
					current_page: 1,
					pre_page: 50,
					count: 0,
					pageSizes:[10, 50, 100]
				},
				dialogTitle: '新增耗材',
				dialogVisible: false,
				EditItem: {},
				disabled: false,
				typeList: [],
				areaList: [],
				optionProps: {value: 'id', label: 'storename', emitPath: false, checkStrictly: true},
				optionProps1: {value: 'id', label: 'cname', emitPath: false},
				files: [],
				logList: [],
				dialogVisible_borrow: false,
				userinfo: {},
				temp_list: [],//todo 未用备用
				showUserDialog: false,
				userDialogType:null,
				dialogVisible_return: false,
				dialogVisible_distribute: false,
				dialogVisible_shenling:false,
				dialogVisible_withdraw: false,
				drawer: false,//高级搜索面板
				FieldItem: {},
				showCusField: false,
				curDate: '',
				printObj: {
					id: "#printDiv",
					popTitle: "",
					extraCss: "",
					extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
				},
				showPrinter:false,
				showBiaoqian:false,
				bqZcList:[],
				dialogErrorVisible: false,
				ErrLog: [],
				page_hc: {
					current_page: 1,
					pre_page: 50,
					count: 0,
					pageSizes:[10, 50, 100]
				},
				dialogVisible_zcList:false,
				dialogVisible_store:false,
				storeNum:null,
				rules_store: {
					num: [
						{validator:checkNum,trigger:'blur'}
					],
                },
			}
		},
		mounted () {
			this.getList();
			this.getType();
			this.getArea();
			this.getCurDate();
			let _this = this
			if (localStorage.getItem("user")) {
				_this.userinfo = JSON.parse(localStorage.getItem("user"))
				// console.log(_this.userinfo)
			}
		},
		methods: {
			//增加库存
			showStore(row){
				console.log(row.id)
				this.EditItem = {
					id:row.id,
                }
                this.dialogVisible_store = true
            },
			saveStore (formName) {
				let _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let params = {}
						params = {..._this.EditItem}
                        _this.$http.post('/api/zc_hc_addstore', params).then(res => {
							_this.dialogVisible_store = false;
							_this.$message.success('保存成功');
							_this.getList()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			onSearch () {
				this.page.current_page = 1
				this.getList()
			},
			onReset () {
				this.queryForm = {}
				this.page.current_page = 1
				this.getList()
			},
			handleCommand (command) {
				let _this = this
				switch (command) {
					case 0:
						document.getElementById("addXlsbtn").click()
						break;
                    case 1:
						if (_this.multipleSelection &&
							_this.multipleSelection.length > 0 ){
							if(!_this.dep_Arr(_this.multipleSelection)) {
								_this.$message.warning('请选择剩余量大于0的资产');
								return
							}else {
								_this.EditItem = {
									sq_date: _this.curDate,
									target_users:[],
									hclist:_this.multipleSelection.map(i=>{
										return Object.assign(i,{num:1,})
									}),
								}
                            }
						}else{
							_this.EditItem = {
								sq_date: _this.curDate,
								target_users:[],
								hclist:[],
							}
                        }
						_this.EditItem = {..._this.EditItem}
						_this.dialogVisible_distribute = true
						_this.disabled = false
						break;
					case 2:
						if (_this.multipleSelection &&
							_this.multipleSelection.length > 0 ){
							if(!_this.dep_Arr(_this.multipleSelection)) {
								_this.$message.warning('请选择剩余量大于0的资产');
								return
							}else {
								_this.EditItem = {
									hclist:_this.multipleSelection.map(i=>{
										return Object.assign(i,{num:1,})
									}),
								}
							}
						}else{
							_this.EditItem = {
								hclist:[],
							}
						}
						_this.EditItem = {..._this.EditItem}
						_this.dialogVisible_shenling = true
						_this.disabled = false
						break;
						
					default:
						break;
				}

			},
			dep_Arr (arr) {
				let a = true
				let fun = function (b) {
					b.map(s => {
						if (s.store_num<=0) {
							a = false
							return;
						}
					})
				}
				fun(arr)
				return a
			},

			uploadCover (e) {
				this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
			},
			handleRemove (file, fileList) {
				console.log(file, fileList);
				this.files = fileList
			},
			uploadPreview (file) {
				// console.log(879,file)
				window.open(file.src || file.url)
			},
			getType () {
				let _this = this
				this.$http.post("/api/zc_type",{type:'hc'}).then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.typeList = result
					})
				})
			},
			getArea () {
				let _this = this
				this.$http.post("/api/zc_store").then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.areaList = result
					})
				})
			},
			// 处理没有children的分组
			deleteEmptyGroup (treeData) {
				return new Promise(resolve => {
					function traversal (data) {
						data.map(item => {
							for (let info in item) {
								if (item['children']) {
									if (item['children'].length > 0) {
										traversal(item['children'])
									}
									if (item['children'].length == 0) {
										delete item['children']
									}
								}
							}
						})
					}

					traversal(treeData)
					resolve(treeData)
				})
			},
			getList () {
				let _this = this
				let params = {}, arr = []
				params.page = _this.page.current_page
				params.pagesize = _this.page.pre_page
				if (_this.queryForm != {}) {
					for (let key in _this.queryForm) {
						if(_this.queryForm[key]){
							arr.push({key: key, value: _this.queryForm[key]})
						}
					}
					params.search = arr
				}
				this.$http.post("/api/zc_hc_list", params).then(res => {
					this.DataList = res.data.data;
					this.page = res.data.page
                    this.page_hc = res.data.page
				})
			},
			handleSelectionChange (val) {
				this.multipleSelection = val;
				// console.log(val)
			},
			showDetails (row) {
				let _this = this
				this.editItem(row,true)
				this.dialogTitle = '耗材详情'
				this.dialogVisible = true
				this.disabled = true
			},
		
			// 派发单
			chooseHC (row) {
				console.log(row, this.EditItem.hclist.length)
				let _this = this
				if (_this.EditItem.hclist&&_this.EditItem.hclist.length > 0) {
					if (_this.dep_Arr1(_this.EditItem.hclist, row.id)) {
						_this.EditItem.hclist.push(Object.assign(row,{
							num:_this.EditItem.cus_paifanum?_this.EditItem.cus_paifanum:1,
						}))
					} else {
						_this.$message.warning('选择重复！')
					}
				} else {
					_this.EditItem.hclist = []
					_this.EditItem.hclist.push(Object.assign(row,{
						num:_this.EditItem.cus_paifanum?_this.EditItem.cus_paifanum:1,
					}))
				}
				_this.EditItem = {..._this.EditItem}
			},
			dep_Arr1 (arr, id) {
				let a = true
				let fun = function (b) {
					b.map(s => {
						if (s.id == id) {
							a = false
							return;
						}
					})
				}
				fun(arr)
				return a
			},
			removeHC (row) {
				let _this = this
				_this.EditItem.hclist.map((z, i) => {
					if (z.id == row.id) {
						_this.EditItem.hclist.splice(i, 1)
					}
				})
				_this.EditItem = {..._this.EditItem}
			},
			applyAllNum(){
				let _this = this
				if (_this.EditItem.hclist&&_this.EditItem.hclist.length > 0) {
					console.log(_this.EditItem.hclist.length,'asasas',_this.EditItem.cus_paifanum)
					let arr = [..._this.EditItem.hclist]
                    arr.map(i=>{
						i.num = _this.EditItem.cus_paifanum?_this.EditItem.cus_paifanum:i.num
						console.log(i.num)
					})
					_this.EditItem.hclist = [...arr]
					_this.EditItem = {..._this.EditItem}
				}
            },
			saveDistribute () {
				let _this = this
				if(_this.EditItem.target_users&&_this.EditItem.target_users.length>0){
					if(_this.EditItem.hclist&&_this.EditItem.hclist.length>0){
						let params = {..._this.EditItem}
						if (this.files.length > 0) {
							params.files = JSON.stringify(_this.files)
						}
						_this.$http.post('/api/zc_hc_paifa', params).then(res => {
							_this.dialogVisible_distribute = false;
							_this.$message.success('保存成功');
							_this.getList()
						})
					}else{
						_this.$message.warning('请选择耗材')
						return;
					}
				}else{
					_this.$message.warning('请选择领用人')
					return;
				}
			},
            // 申领单
			saveShenling(row){
				let _this = this
				if(!_this.EditItem.name) {
					_this.$message.warning('请选择申领人')
					return;
				}
				if (_this.EditItem.hclist && _this.EditItem.hclist.length > 0) {
					let params = {..._this.EditItem}
					if (this.files.length > 0) {
						params.files = JSON.stringify(_this.files)
					}
					_this.$http.post('/api/zc_hc_shenling', params).then(res => {
						if (res.data.code != 200) {
							_this.$message.error(res.data.msg);
						} else {
							_this.dialogVisible_shenling = false;
							_this.$message.success('申领成功');
							_this.getList()
						}
					})
				} else {
					_this.$message.warning('请选择耗材')
					return;
				}
			},
			handleShenlingSP(row){
				let _this = this
				this.$confirm('是否审批同意?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.$http.post('/api/zc_hc_sl_sp', {id:String(row.id)}).then(res => {
						if(res.data.code!=200){
							_this.$message.error(res.data.msg);
						}else{
							_this.$message.success('申领成功');
							_this.getList()
						}
					})
				})
			},
            // 新增
			add () {
				let _this = this
				this.dialogTitle = '新增耗材'
				this.dialogVisible = true
				this.disabled = false
				this.EditItem = {
					zc_date: _this.curDate,
				}
				this.files = []
				this.EditItem = {..._this.EditItem}
				this.files = [..._this.files]
			},
			editItem (row,flag) {
				let _this = this
				this.dialogTitle = '编辑'
				this.dialogVisible = true
				this.disabled = false
				let arr = [], cus_arr = []
				this.$http.post("/api/zc_hc_info", {id: row.id}).then(res => {
					let rData = res.data
					_this.EditItem = rData
					if(rData.logs&&rData.logs.length>0){
						rData.logs.map(l=>{
							l.union_id = l.target_uid
							l.name = l.target_name
							l.num = l.dz_num
						})
						_this.EditItem.target_users = rData.logs
						_this.EditItem = {..._this.EditItem}
					}
					_this.logList = rData.logs;
					if (rData.files != '' && rData.files != null && rData.files != undefined) {
						arr = JSON.parse(rData.files)
						_this.files = arr
					}
					if (row.remark && row.remark != '') {
						_this.EditItem.zdjson = JSON.parse(row.remark)
					}
					if(flag){
						for(let key in _this.EditItem){
							if(!_this.EditItem[key]){
								_this.EditItem[key] = '-'
							}
						}
                    }
					_this.EditItem = {..._this.EditItem}
				})
			},
			saveForm () {
				let _this = this
				let params = {}
				params = {..._this.EditItem}
				if ( !params.title) {
					_this.$message.warning('请填写耗材名称！')
					return
				}
				if ( !params.zc_typeid) {
					_this.$message.warning('请选择耗材类型！')
					return
				}
				if ( !params.zc_storeid) {
					_this.$message.warning('请选择所在仓库！')
					return
				}
				if ( !params.store_num) {
					_this.$message.warning('请填写耗材数量！')
					return
				}
				if (this.files.length > 0) {
					params.files = JSON.stringify(_this.files)
				}
				params.remark = JSON.stringify(params.zdjson)
				this.$http.post('/api/zc_hc_save', params).then(res => {
					_this.dialogVisible = false;
					this.$message({
						type: 'success',
						message: '保存成功'
					});
					_this.getList()
				})
			},
			removeItem (item) {
				let _this = this
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/zc_hc_delete", {
						id: item.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});

						_this.getList()
					})

				}).catch(() => {

				});
			},
			delSlct() {
				let _this = this
				if(_this.multipleSelection.length == 0){
					_this.$message.warning('请选择至少一条数据')
				}else{
					_this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						_this.$http.post("/api/zc_hc_delete", {
							id: _this.multipleSelection.map(item=>{return item.id}).join(',')
						}).then(res => {
							_this.$message.success('删除成功');
							_this.getList()
						})
					}).catch(() => {

					});
				}
			},
			handleSizeChange(val){
				this.page.current_page = 1
				this.page.pre_page = val
				this.getList();
			},
			paginate (val) {
				this.page.current_page = val
				this.getList();
			},
			handleSizeChange_hc(val){
				this.page_hc.current_page = 1
				this.page_hc.pre_page = val
				this.getList();
			},
			paginate_hc (val) {
				this.page_hc.current_page = val
				this.getList();
			},
			handleCascaderChange (e) {
				console.log(e)
			},
			handleCascaderChange1 (e) {
				if (e) {
					let _this = this
					_this.EditItem.zc_typeid = e
					let a = _this.$refs['myCascader1'].getCheckedNodes()[0].data
					// console.log(1114, a)
					_this.FieldItem = a
					_this.EditItem.ename = a.ename
					_this.EditItem.jldw = a.unit
					if (a.zdjson && a.zdjson != '') {
						_this.EditItem.zdjson = JSON.parse(a.zdjson)
					}
					_this.EditItem = {..._this.EditItem}
				}
			},
			handleCascaderChange2 (e) {
				// console.log(e)
				if (e) {
					let _this = this
					_this.EditItem.zc_whereid = e
					let a = _this.$refs['myCascader2'].getCheckedNodes()[0].data
					let allCates = _this.getFathersById(e, _this.areaList, 'id', 'children', 'storename')
					allCates.pop();
					let ss = allCates.length > 0 ? `${allCates.join('/')}/${a.storename}` : a.storename
					_this.EditItem.zc_where = ss
					console.log(_this.EditItem.zc_where)
					_this.EditItem = {..._this.EditItem}
				}
			},
			/**
			 * 查找匹配的完整路径(arrRes:id集合;arrText:中文集合;)
			 * id:     匹配的值
			 * data: 匹配的数组数据
			 * prop: 匹配的字段名
			 * children: 子集label
			 * cname: 输出数组需要的字段名
			 */
			getFathersById (id, data, prop, children, cname) {
				var arrRes = [], arrText = []
				const rev = (data, nodeId) => {
					for (var i = 0, length = data.length; i < length; i ++) {
						const node = data[i]
						if (node[prop] == nodeId) {
							arrRes.unshift(node[prop])
							arrText.unshift(node[cname])
							return true
						} else {
							if (node[children] && node[children].length) {
								if (rev(node[children], nodeId)) {
									arrRes.unshift(node[prop])
									arrText.unshift(node[cname])
									return true
								}
							}
						}
					}
					return false
				}
				rev(data, id)
				return arrText  //返回中文集合
			},

			showUser(type){
				if(type){
					this.userDialogType = type
				}
				this.showUserDialog = true
			},
			del_target_users(index){
				let _this = this
				this.EditItem.target_users.splice(index, 1)
				this.EditItem = {..._this.EditItem}
			},
			getUser (e) {
				console.log(e)
				let _this = this
				let arr = []
				if (e && e.length > 0) {
					if(this.userDialogType==1){
						e.map((item,idx)=>{
							arr.push({
								union_id:item.union_id,
								name:item.name,
							})
							if(_this.EditItem.target_users&&_this.EditItem.target_users.length>0) {
								_this.EditItem.target_users.map(orgnl => {
									if (orgnl.union_id == item.union_id) {
										arr.splice(idx, 1)
									}
								})
							}
						})
						_this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
						_this.EditItem = {..._this.EditItem}
                    }else if(this.userDialogType==2){
						_this.EditItem.target_uid = e[0].union_id
						_this.EditItem.name = e[0].name
						_this.EditItem = {..._this.EditItem}
					}
				
				}
				_this.showUserDialog = false
			},
			closeUser () {
				this.showUserDialog = false
			},
			addCusField () {
				let _this = this
				if ( !this.FieldItem.id) {
					this.$message.warning('请先选择资产分类！')
					return;
				}
				this.showCusField = true
				console.log(1103, _this.FieldItem.zdjson)
				if (_this.FieldItem.zdjson && _this.FieldItem.zdjson != '' && _this.FieldItem.zdjson != 'null' && _this.FieldItem.zdjson != 'undefined') {
					_this.FieldItem.zdjson = JSON.parse(_this.FieldItem.zdjson)
				} else {
					_this.FieldItem.zdjson = []
				}
				_this.FieldItem = {..._this.FieldItem}
			},
			saveCusField () {
				let _this = this
				let objvalue = _this.EditItem.zdjson    //保存后回显防止旧值被覆盖
				let params = {}, obj = {}
				obj = JSON.parse(JSON.stringify(_this.FieldItem))
				params = {...obj}
				params.remark = ""
				params.zdjson = JSON.stringify(obj.zdjson)
				this.$http.post("/api/zc_type_save", params).then(res => {
					_this.showCusField = false
					_this.$message({
						type: 'success',
						message: '保存成功'
					});
					_this.getType()
					_this.EditItem.zdjson = JSON.parse(params.zdjson)
					for (let a in objvalue) {
						for (let b in _this.EditItem.zdjson) {
							if (a == b && objvalue[a] != '') {
								_this.EditItem.zdjson[b] = objvalue[a]
							}
						}
					}
					_this.EditItem = {..._this.EditItem}
				})
			},
			addCus () {
				let _this = this
				this.FieldItem.zdjson.push({})
				this.FieldItem = {..._this.FieldItem}
			},
			delCus (item, index) {
				let _this = this
				this.FieldItem.zdjson.splice(index, 1)
				this.FieldItem = {..._this.FieldItem}
			},
		
			print_ruku(){
				this.$alert("敬请期待")
				return;
				if (this.multipleSelection.length == 0) {
					this.$message.warning('请选择一条数据');
					return
				}else {
					this.showPrinter = true
				}
			},
			getCurDate () {
				let d = new Date();
				let year = d.getFullYear(); //获取完整的年份(4位)
				let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
				let date = d.getDate(); //获取当前日(1-31)
				d.getDay(); //获取当前星期X(0-6,0代表星期天)
				d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
				d.getHours(); //获取当前小时数(0-23)
				d.getMinutes(); //获取当前分钟数(0-59)
				d.getSeconds(); //获取当前秒数(0-59)
				d.getMilliseconds(); //获取当前毫秒数(0-999)
				d.toLocaleDateString(); //获取当前日期
				let mytime = d.toLocaleTimeString(); //获取当前时间
				d.toLocaleString(); //获取日期与时间
				this.curDate = `${year}-${month}-${date}`
			},
			uploadAttch(e){
				console.log(e)
				this.$http.post("/api/import_zc_xls",{url:e.src}).then(res=>{
					if (res.data.errordata.length > 0) {
						this.ErrLog = res.data.errordata
						this.dialogErrorVisible = true;

					}
					this.getList();
				})
			}
		}
	}
</script>

<style lang="less" type="text/scss">
 
    
    .pagezclist {
        .el-dropdown-link {
            cursor: pointer;
            color: #409EFF;
            font-size: 12px;
        }
        .el-icon-arrow-down {
            font-size: 12px;
        }
        .labelCss {
            .el-form-item__label {
                color: #909399 !important;
                font-size: 12px;
            }
        }
        
        .el-input, .el-select, .el-cascader {
            width: 100% !important;
        }
        
        .el-dropdown {
            vertical-align: top;
        }
        
        .el-dropdown + .el-dropdown {
            margin-left: 15px;
        }
        
        .el-icon-arrow-down {
            font-size: 12px;
        }
        
        .cus_dropdown {
            .el-button--primary {
                color: #409EFF !important;
                background-color: #Ecf5ff !important;
                border-color: #b3d8ff !important;
            }
        }
        
        .el-divider {
            margin-top: 0;
        }
        
        .el-drawer__wrapper {
            top: 70px;
        }
        
        .drawer_box,.targetUsers {
            padding: 10px;
            
            .el-form-item {
                margin-bottom: 0px;
            }
        }
        .updimage{
            width: 100px; height: 100px; display: inline-block; vertical-align: top; margin: 0 10px 10px 0; border: 1px dotted #ccc;
            background-size: 100% auto;
            background-position: center;
            overflow: hidden;
            background-repeat: no-repeat;
            cursor: pointer;
        }
        .cus_page {
            margin-top: 10px;
            .el-pagination__sizes {
                width: 130px;
            }
        }
    }


</style>
